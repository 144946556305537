// 
// user-variables.scss
// Use this to overwrite Bootstrap and theme variables
//

// Example of a variable override to change theme's background color
// Remove the "//" to comment it in and see it in action!
// $body-bg: #12263F;

$primary: #22b4a4;
$primary-negation: #dd4b5b;
$primary-desat: #3c9b90;
$font-family-base: 'GoldbillXLReg';


$primary-desat: #3c9b90 !default; // NEW
$success: #22b4a4 !default;
$info: #384C74 !default;
$warning: #FAD776 !default;
$danger: #dd4b5b !default;



// xploras logo box
.xploras_logo_box {
    display: flex;
    align-items: center;

    > .xploras_logo_text {
        font-family: Gismonda, sans-serif !important; /*Specify your font name here*/
        color: black;
        font-size: 1.5em;
    }

    > img {
        height: 2em;
    }
}

.xploras_gismonda {
  font-family: Gismonda, sans-serif !important; /*Specify your font name here*/
  color: black;
}

// fantawesome icons
.xploras-fa {
    color: $primary-desat;
}

.features-fa{
    font-size: 3em;
}

// cookie consent modal
.cookieConsentModal .modal-dialog {
    position:fixed;
    bottom:0;
    right:0;
    margin:0;
}

// call to action
.cta {
  @extend .btn;
  @extend .lift;
  border: none;
  color: white !important;
  background-color: $primary-negation !important;
  font-size: 1.5em; 
  font-weight: bolder; 
  text-transform: uppercase;
}

// ================ DATA TABLES
.signalstable > *,
.signalstable thead,
.signalstable tr,
.signalstable tbody,
.signalstable th,
.signalstable td {
  text-align: center !important;
  vertical-align: middle !important;
  white-space: nowrap !important;
  > td:nth-child(2) { // star ratings... i know its shit to use nth-chile, but nothing else works lol...
    color: $primary-desat !important;
  }
  > td:nth-child(7) > *{ // favorites icons
    cursor: pointer;
    color: $primary-negation !important;
  }
}

ul.pagination{
  display: inline-flex;
}

div.dataTables_wrapper div.dataTables_paginate{
  text-align: center;
}

.ret_plus{
  color: $primary-desat;
}

.ret_minus{
  color: $primary-negation;
}

section.analytics-legend > div > div > p > b {
  font-family: monospace;
}

// some shit setting for filter icons
table.dataTable>thead .sorting:before, table.dataTable>thead .sorting:after, table.dataTable>thead .sorting_asc:before, table.dataTable>thead .sorting_asc:after, table.dataTable>thead .sorting_desc:before, table.dataTable>thead .sorting_desc:after, table.dataTable>thead .sorting_asc_disabled:before, table.dataTable>thead .sorting_asc_disabled:after, table.dataTable>thead .sorting_desc_disabled:before, table.dataTable>thead .sorting_desc_disabled:after {
  bottom: 1.5em !important;
}

/*

// fix bug with bottom edge styling
table.dataTable{
  border-bottom:0px;
}

*/

// align pagination
.dataTables_paginate{
  align-items:center !important;
  text-align: center !important;
  margin: 10px 0px !important;
}

// ================ MOBILE STYLES
@media screen and (max-width: 767px) {

  // ANALYTICS TABLE BUTTONS
  li.paginate_button.previous {
      display: inline;
  }

  li.paginate_button.next {
      display: inline;
  }

  li.paginate_button {
      display: none;
  }

  // TABLES

  // mobile tables padding
  .dataTables_scrollHead>.dataTables_scrollHeadInner>.table>:not(caption)>*>* , .table>:not(caption)>*>:not(.sorting){
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  
  // some shit setting for filter icons
  table.dataTable>thead .sorting:before, table.dataTable>thead .sorting:after, table.dataTable>thead .sorting_asc:before, table.dataTable>thead .sorting_asc:after, table.dataTable>thead .sorting_desc:before, table.dataTable>thead .sorting_desc:after, table.dataTable>thead .sorting_asc_disabled:before, table.dataTable>thead .sorting_asc_disabled:after, table.dataTable>thead .sorting_desc_disabled:before, table.dataTable>thead .sorting_desc_disabled:after {
    bottom: 0.4em !important;
  }

}